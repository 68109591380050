/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import View from "../components/View";
import {
  AuctionType,
  BuyerInvoice,
  Order,
  OrderStatus,
  OrderType,
  Payment,
  PaymentProvider,
  PaymentStatus,
} from "../types";
import { get, post } from "../utils/fetch";
import { apiUrl } from "../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import Chip from "../components/core/Chip";
import Fields from "../components/Fields";
import Button from "../components/core/Button";
import Icon from "../components/core/Icon";
import { useEnvironmentContext } from "../context/EnvironmentContext";
import Loader from "../components/core/Loader";
import List from "../components/core/List";
import Toolbar from "../components/core/Toolbar";
import toast from "react-hot-toast";
import AreYouSureModal from "../components/modals/AreYouSureModal";
import { useModalContext } from "../context/ModalContext";
import OrderStatusChip from "../components/OrderStatusChip";
import { useAuthContext } from "../context/AuthContext";
import CreateInvoiceModal from "../components/modals/CreateInvoiceModal";
import SwitchHashrateDeliveryModal from "../components/modals/SwitchHashrateDeliveryModal";
import { isBeforeOrderStatus } from "../utils/order";

const PAYMENT_PROVIDERS: { [key: string]: string } = {
  [PaymentProvider.BitGo]: "BitGo",
  [PaymentProvider.OpenNode]: "OpenNode",
};

export default function Orders() {
  const params = useParams();
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();
  const { showModal, hideModal } = useModalContext();
  const navigate = useNavigate();

  const [loadingOrder, setLoadingOrder] = React.useState<boolean>(false);
  const [loadingCreateInvoice, setLoadingCreateInvoice] =
    React.useState<boolean>(false);
  const [loadingInvoices, setLoadingInvoices] = React.useState<boolean>(false);
  const [loadingPayments, setLoadingPayments] = React.useState<boolean>(false);
  // const [loadingNextEpoch, setLoadingNextEpoch] = React.useState<boolean>(false);
  const [paymentReminderLoading, setPaymentReminderLoading] =
    React.useState<boolean>(false);
  const [startHashrateDeliveryLoading, setStartHashrateDeliveryLoading] =
    React.useState(false);
  const [endHashrateDeliveryLoading, setEndHashrateDeliveryLoading] =
    React.useState(false);
  const [switchHashrateDeliveryLoading, setSwitchHashrateDeliveryLoading] =
    React.useState(false);

  const [order, setOrder] = React.useState<Order | undefined>(undefined);
  const [payments, setPayments] = React.useState([]);
  const [invoices, setInvoices] = React.useState([]);
  const [nextEpoch, setNextEpoch] = React.useState<number | undefined>(
    undefined
  );

  const loadOrder = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingOrder(true);
      const res = await get(
        apiUrl(environment, `/api/ops/orders/${params.orderId}`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrder(res);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingOrder(false);
    }
  };

  const loadInvoices = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingInvoices(true);
      const res = await get(
        apiUrl(environment, `/api/ops/orders/${params.orderId}/invoices`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInvoices(res);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingInvoices(false);
    }
  };

  const loadPayments = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      setLoadingPayments(true);
      const res = await get(
        apiUrl(environment, `/api/ops/orders/${params.orderId}/payments`),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayments(res);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingPayments(false);
    }
  };

  const loadNextEpoch = async () => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    try {
      // setLoadingNextEpoch(true);
      const res = await get(apiUrl(environment, `/api/ops/mining/next-epoch`), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNextEpoch(res.epoch_number);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-left" });
    } finally {
      // setLoadingNextEpoch(false);
    }
  };

  const handleStartHashrateDelivery = () => {
    if (!order || !token) {
      return;
    }

    showModal("are-you-sure", {
      title: "Start hashrate delivery",
      content: "Confirming this prompt will start a hashrate delivery.",
      onCancel: () => {},
      onConfirm: async () => {
        try {
          hideModal("are-you-sure");
          setStartHashrateDeliveryLoading(true);
          await post(
            apiUrl(environment, `/api/ops/orders/start-hashrate-delivery`),
            {
              order_id: order.id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          await loadOrder();
        } catch (ex: any) {
          toast.error(ex.message, { position: "bottom-left" });
        } finally {
          setStartHashrateDeliveryLoading(false);
        }
      },
    });
  };

  const handleEndHashrateDelivery = async () => {
    if (!order || !token) {
      return;
    }

    showModal("are-you-sure", {
      title: "End hashrate delivery",
      content: "Confirming this prompt will end this hashrate delivery",
      onCancel: () => {},
      onConfirm: async () => {
        try {
          hideModal("are-you-sure");
          setEndHashrateDeliveryLoading(true);
          await post(
            apiUrl(environment, `/api/ops/orders/end-hashrate-delivery`),
            {
              order_id: order.id,
              reason: "Manually stopped via admin panel",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          await loadOrder();
        } catch (ex: any) {
          toast.error(ex.message, { position: "bottom-left" });
        } finally {
          setEndHashrateDeliveryLoading(false);
        }
      },
    });
  };

  const handleSendPaymentReminder = async (
    e: React.MouseEvent<HTMLElement>,
    payment?: Payment
  ) => {
    if (process.env.REACT_APP_ENV !== "local" && !token) {
      return;
    }

    e.preventDefault();

    showModal("are-you-sure", {
      title: "Send payment reminder",
      content: `You are about to send a payment reminder to ${order?.account.email} for Order ${order?.id}. Do you want to continue?`,
      onCancel: () => {},
      onConfirm: async () => {
        try {
          hideModal("are-you-sure");
          setPaymentReminderLoading(true);

          const payload: { payment_id?: number } = {};
          if (payment) {
            payload.payment_id = payment.id;
          }

          await post(
            apiUrl(
              environment,
              `/api/ops/orders/${params.orderId}/send-payment-reminder`
            ),
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          toast.success("Payment reminder sent", { position: "bottom-left" });
        } catch (ex: any) {
          toast.error(ex.message, { position: "bottom-left" });
        } finally {
          setPaymentReminderLoading(false);
        }
      },
    });
  };

  const handleCreateInvoice = async () => {
      if ((process.env.REACT_APP_ENV !== "local" && !token) || !order) {
          return;
      }

      try {
          setLoadingCreateInvoice(true);
          const payload =
              order.type === OrderType.BlockParty || order.type === OrderType.Direct
                  ? {}
                  : order.auction?.auction_type.type === "immediate_delivery"
                  ? {}
                  : order.auction?.epoch
                  ? { epoch_number: order.auction.epoch.epoch_number }
                  : {};

          await post(
              apiUrl(environment, `/api/ops/orders/${order.id}/invoices/create`),
              payload,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          );
      } catch (ex: any) {
          toast.error(ex.message, { position: "bottom-left" });
      } finally {
          setLoadingCreateInvoice(false);
          await loadOrder();
      }
  };


  const handleSwitchHashrateDelivery = () => {
    if ((process.env.REACT_APP_ENV !== "local" && !token) || !order) {
      return;
    }

    showModal("switch-hashrate-delivery", {
      onCancel: () => {},
      onConfirm: async (from: Order, to: Order) => {
        setSwitchHashrateDeliveryLoading(true);
        try {
          await post(
            apiUrl(environment, `/api/ops/orders/switch-hashrate-delivery`),
            {
              from_order_id: from.id,
              reason: "Manually switched via admin panel",
              to_order_id: to.id,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          toast.success("Hashrate delivery switched", {
            position: "bottom-left",
          });
          hideModal("switch-hashrate-delivery");
        } catch (ex: any) {
          toast.error(ex.message, { position: "bottom-left" });
        } finally {
          setSwitchHashrateDeliveryLoading(false);
        }
      },
    });
  };

  const showStartHashrateDelivery = () => {
    if (!order || !nextEpoch) {
      return false;
    }

    if (
      order.type === OrderType.BlockParty ||
      order.type === OrderType.Direct
    ) {
      return false;
    }

    if (order.status !== OrderStatus.Paid_1) {
      return false;
    }

    if (!order.auction) {
      return false;
    }

    if (order.auction.auction_type.type === AuctionType.ImmediateDelivery) {
      return true;
    }

    return order.auction.epoch.epoch_number <= nextEpoch;
  };

  React.useEffect(() => {
    loadOrder();
    loadNextEpoch();
  }, [params, environment, token]);

  React.useEffect(() => {
    if (order) {
      loadInvoices();
      loadPayments();
    }
  }, [order]);

  const showEndHashrateDeliveryButton =
    order && order.status === OrderStatus.DeliveryStarted;

  const showSwitchHashrateDeliveryButton =
    order && order.status === OrderStatus.DeliveryStarted;

  // const showCreateInvoiceButton =
  //   order &&
  //   hasPassedOrEqualsOrderStatus(order.status, OrderStatus.DeliveryStarted);
  const showCreateInvoiceButton = true;
  return (
    <>
      <Topbar />
      <div className="flex w-full h-screen-excluding-topbar">
        <Sidebar />
        <View>
          {(!order || loadingOrder) && (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="w-8 h-8 text-white" />
            </div>
          )}
          {order && (
            <>
              <Toolbar className="p-1 pl-4">
                {order.type === OrderType.BlockParty && (
                  <h1 className="text-white text-sm font-semibold">
                    Order {order.id} - {order.block_party!.name} -{" "}
                    {order.account.email}
                  </h1>
                )}
                {order.type === OrderType.Auction && (
                  <h1 className="text-white text-sm font-semibold">
                    Order {order.id} - {order.auction!.title} -{" "}
                    {order.account.email}
                  </h1>
                )}
                {order.type === OrderType.Direct && (
                  <h1 className="text-white text-sm font-semibold">
                    Order {order.id} - Direct
                    {order.account.email}
                  </h1>
                )}
                <div className="flex items-center justify-center gap-1">
                  {isBeforeOrderStatus(order.status, OrderStatus.Paid_2) && (
                    <Button
                      color="purple"
                      onClick={(e: any) => handleSendPaymentReminder(e)}
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                    >
                      {paymentReminderLoading && <Loader className="w-4 h-4" />}
                      {!paymentReminderLoading && (
                        <Icon icon="moneyBillWave" className="w-4 h-4" />
                      )}
                      <span className="text-xs">Send payment reminder</span>
                    </Button>
                  )}
                  <Button
                    className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                    color="blue"
                    onClick={handleCreateInvoice}
                    disabled={loadingCreateInvoice || !showCreateInvoiceButton}
                  >
                    {loadingCreateInvoice && <Loader className="w-4 h-4" />}
                    {!loadingCreateInvoice && (
                      <Icon icon="plus" className="w-4 h-4" />
                    )}
                    <span className="text-xs">Create invoice</span>
                  </Button>
                  {showSwitchHashrateDeliveryButton && (
                    <Button
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                      color="purple"
                      onClick={handleSwitchHashrateDelivery}
                      disabled={switchHashrateDeliveryLoading}
                    >
                      {switchHashrateDeliveryLoading && (
                        <Loader className="w-4 h-4" />
                      )}
                      {!switchHashrateDeliveryLoading && (
                        <Icon
                          icon="arrowRightArrowLeft"
                          className="w-4 h-4 text-white"
                        />
                      )}
                      <span className="text-xs">Switch hashrate delivery</span>
                    </Button>
                  )}
                  {showStartHashrateDelivery() && (
                    <Button
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                      color="green"
                      onClick={handleStartHashrateDelivery}
                      disabled={startHashrateDeliveryLoading}
                    >
                      {startHashrateDeliveryLoading && (
                        <Loader className="w-4 h-4" />
                      )}
                      {!startHashrateDeliveryLoading && (
                        <Icon
                          icon="circlePlay"
                          className="w-4 h-4 text-white"
                        />
                      )}
                      <span className="text-xs">Start hashrate delivery</span>
                    </Button>
                  )}
                  {showEndHashrateDeliveryButton && (
                    <Button
                      className="h-8 rounded flex items-center justify-start gap-2 uppercase tracking-wide px-2"
                      color="red"
                      onClick={handleEndHashrateDelivery}
                      disabled={endHashrateDeliveryLoading}
                    >
                      {endHashrateDeliveryLoading && (
                        <Loader className="w-4 h-4" />
                      )}
                      {!endHashrateDeliveryLoading && (
                        <Icon
                          icon="circleStop"
                          className="w-4 h-4 text-white"
                        />
                      )}
                      <span className="text-xs">End hashrate delivery</span>
                    </Button>
                  )}
                </div>
              </Toolbar>

              <div className="px-3 pb-3">
                <h2 className="text-xl text-white font-semibold mb-3">
                  Fields
                </h2>
                <Fields
                  data={order}
                  schema={{
                    fields: [
                      {
                        label: "Status",
                        type: "component",
                        meta: (order: Order) => (
                          <div className="flex items-center justify-start">
                            <OrderStatusChip value={order.status} />
                          </div>
                        ),
                      },
                      {
                        label: "Auction Type",
                        type: "component",
                        meta: (order: Order) =>
                          order.type === OrderType.BlockParty ? (
                            <span className="text-white">Block Party</span>
                          ) : order.type === OrderType.Direct ? (
                            "Direct"
                          ) : (
                            <span className="text-white">
                              {order.auction!.auction_type.type}
                            </span>
                          ),
                      },
                      {
                        key: "original_total",
                        label: "Original total",
                        type: "sats",
                      },
                      { key: "total", label: "Total", type: "sats" },
                      {
                        key: "mining_deposit",
                        label: "Mining deposit",
                        type: "sats",
                      },
                      {
                        key: "auction_fee",
                        label: "Auction fee",
                        type: "sats",
                      },
                      {
                        key: "expires_at",
                        label: "Expires at",
                        type: "datetime",
                        meta: "MM/dd/yyyy HH:mm:ss",
                      },
                    ],
                  }}
                />
              </div>
              <div className="px-3 pb-3">
                <h2 className="text-xl text-white font-semibold mb-3">
                  Invoices
                </h2>
                <List
                  schema={[
                    { label: "ID", key: "id", type: "number", align: "left" },
                    {
                      label: "Epoch",
                      key: "report.epoch_number",
                      type: "number",
                      align: "left",
                    },
                    {
                      label: "Hashrate start",
                      key: "report.hashrate_start",
                      type: "datetime",
                      align: "left",
                    },
                    {
                      label: "Hashrate end",
                      key: "report.hashrate_end",
                      type: "datetime",
                      align: "left",
                    },
                    {
                      label: "Status",
                      key: (invoice: BuyerInvoice) =>
                        invoice.is_email_sent ? (
                          <span className="text-green-500 text-sm font-semibold">
                            E-mail sent
                          </span>
                        ) : (
                          <span className="text-red-500 text-sm font-semibold">
                            Draft
                          </span>
                        ),
                      type: "custom",
                    },
                    {
                      label: "Mining Status",
                      key: "report.mining_status",
                      type: "string",
                      align: "left",
                    },
                  ]}
                  data={invoices}
                  loading={loadingInvoices}
                  onClick={(invoice: BuyerInvoice) =>
                    navigate(
                      `/accounts/${invoice.report.order.account.id}/invoice/${invoice.id}`
                    )
                  }
                />
              </div>
              <div className="px-3 pb-3">
                <h2 className="text-xl text-white font-semibold mb-3">
                  Payments
                </h2>
                <List
                  data={payments}
                  loading={loadingPayments}
                  onClick={(payment: Payment) =>
                    navigate(`/payments/${payment.id}`)
                  }
                  schema={[
                    {
                      label: "ID",
                      type: "number",
                      key: "id",
                      align: "left",
                    },
                    {
                      label: "Original amount",
                      type: "sats",
                      key: "original_amount",
                      align: "left",
                    },
                    {
                      label: "Amount",
                      type: "sats",
                      key: "amount",
                      align: "left",
                    },
                    {
                      label: "Provider",
                      type: "custom",
                      key: (payment: Payment) => (
                        <span className="text-white text-sm">
                          {PAYMENT_PROVIDERS[payment.provider]}
                        </span>
                      ),
                    },
                    {
                      label: "Status",
                      type: "custom",
                      key: (payment: Payment) => (
                        <Chip
                          className={clsx("", {
                            "bg-zinc-400":
                              payment.status === PaymentStatus.Unpaid,
                            "bg-red-500":
                              payment.status === PaymentStatus.Expired,
                            "bg-blue-500":
                              payment.status === PaymentStatus.Processing,
                            "bg-green-500":
                              payment.status === PaymentStatus.Paid,
                            "bg-orange-500":
                              payment.status === PaymentStatus.Underpaid,
                            "bg-purple-500":
                              payment.status === PaymentStatus.Refunded,
                          })}
                        >
                          {payment.status}
                        </Chip>
                      ),
                    },
                    {
                      label: "Actions",
                      type: "custom",
                      key: (payment: Payment) => {
                        if (
                          !isBeforeOrderStatus(order.status, OrderStatus.Paid_2)
                        ) {
                          return <span className="text-white text-sm">-</span>;
                        }
                        return (
                          <Button
                            disabled={paymentReminderLoading}
                            onClick={(e) =>
                              handleSendPaymentReminder(e, payment)
                            }
                            className={clsx(
                              "col-span-2 h-8 rounded w-full px-4 text-sm font-semibold text-white bg-red-700 hover:bg-red-500 flex items-center px-4",
                              {
                                "justify-center": paymentReminderLoading,
                                "justify-start": !paymentReminderLoading,
                              }
                            )}
                          >
                            {paymentReminderLoading && (
                              <Loader className="text-white h-5 w-5" />
                            )}
                            {!paymentReminderLoading && (
                              <div className="flex items-center justify-start gap-4">
                                <Icon icon="envelope" className="w-4 h-4" />
                                <span className="text-xs tracking-wide uppercase whitespace-nowrap">
                                  Send E-mail reminder
                                </span>
                              </div>
                            )}
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              </div>
            </>
          )}
        </View>
      </div>
      <AreYouSureModal />
      <CreateInvoiceModal orderId={Number(params.orderId)} />
      <SwitchHashrateDeliveryModal order={order} />
    </>
  );
}
